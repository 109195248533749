<template>
  <div class="help">
    <mt-header :title="$t('help')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="box" v-for="(item, index) in data" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="list">
          <div class="item" v-for="(it, i) in item.list" :key="i" :class="{ isUnfold: select.indexOf(`${index}-${i}`) >= 0 }">
            <div class="icon">
              <svg t="1618564042236" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30156" width="10" height="10">
                <path d="M691.509035 519.520267L225.841479 100.313873c-14.783097-11.615291-21.11871-30.62213-17.950904-49.628969 3.167807-17.950904 16.894968-33.789936 34.845872-39.069613 17.950904-5.279678 38.013678 0 50.684904 12.671226l506.849041 456.164136c10.559355 9.50342 16.894968 23.230581 16.894968 38.013679s-6.335613 27.454323-16.894968 38.013678l-506.849041 456.164137c-21.11871 16.894968-51.74084 14.783097-69.691743-5.279678-17.950904-20.062775-16.894968-50.684904 1.055935-69.691743L691.509035 519.520267zM225.841479 937.670726" p-id="30157"></path>
              </svg>
            </div>
            <div class="content">
              <div class="title" @click="click(`${index}-${i}`)">{{ it.title }}</div>
              <div class="value" v-html="it.value"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
export default {
  name: 'Help',
  components: {
    callPhone,
  },
  data () {
    return {
      select: [],
      data: [
        {
          title: 'Registration FAQs',
          list: [
            {
              title: 'Common questions',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'What documents are used for the loan？',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'How long does it take for my loan to be approved？',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'Interest rate？',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'Is my app safe?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'Telephone support',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
          ]
        },
        {
          title: 'How to borrow money?',
          list: [
            {
              title: 'How to get a higher level loan?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'How do I know if I\'ve received a loan?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'What bank account can I borrow money from?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'If our loan application fails, what should I do.',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'How long do I have to wait before I can transfer you to the bank card?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'Why did I fail to borrow?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
            {
              title: 'How to withdraw money?',
              value: '1.Filipino，22-50 years old \n 2.Have stable income and repayment ability'
            },
          ]
        }
      ]
    }
  },
  methods: {
    click(val) {
      let index = this.select.indexOf(val)
      if (index < 0) {
        this.select.push(val)
      } else {
        this.select.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  > .content {
    padding: 22px;
    .box {
      > .title {
        padding: 20px 0px 10px 0px;
        color: #F15234;
        font-size: 18px;
        font-weight: bold;
      }
      .list {
        .item {
          margin-top: 15px;
          display: flex;
          &.isUnfold {
            .icon {
              color: #F15234;
              svg {
                transform:rotate(90deg);
              }
            }
            .content {
              .title {
                color: #F15234;
              }
              .value {
                display: block;
              }
            }
          }
          .icon {
            color: #5B5B5B;
            margin: 3px 10px 0px 3px;
            svg {
              vertical-align: top;
            }
          }
          .content {
            flex: 1;
            .title {
              color: #5B5B5B;
              font-size: 13px;
            }
            .value {
              padding: 10px 0px;
              display: none;
              color: #5B5B5B;
              line-height: 22px;
              border-bottom: 1px solid #E6E6E6;
            }
          }
        }
      }
    }
  }
}
</style>